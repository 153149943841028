.MuiCardMedia-media {
  height: auto;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Updated this line */
  position: fixed;
  top: 0;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* For Firefox */
  z-index: 2;
}


.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.item-exit {
  opacity: 1;
}

.filters {
  position: sticky;
  left: 0;
  display: flex;
  background-color: #d8d8d8;
  z-index: 4;
  gap: 15px;
  padding-right: 25px;
  border-right: solid 1px black;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.sorting {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  z-index: 3;
  padding: 10px 0;
}

.sort-select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  position: relative;
}

.sort-order-checkbox {
  margin-top: 5px;
  right: 100px;
  position: relative;
}

.footer {
  position: fixed;
  background-color: white;
  display: flex;
  align-items: center;
  border: 1px solid black;
  right: 25px;
  bottom: 20px;
  color: black;
  z-index: 5;
  height: auto;
  font-size: 0.8em;
  text-align: right;
  padding: 10px;
}

.footer a {
  color: black;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

body {
overflow-x: hidden;
}

.main::-webkit-scrollbar {
  display: none;
}

.menu::-webkit-scrollbar {
  display: none;
}

.menu {
  overflow-x: scroll;
    display: flex;
    gap: 15px;

}

.App {
  padding: 0 20px;
  overflow: visible;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.BrandSelector {
  flex: 1;
  /* Other styles */
}

.ItemGrid {
  flex: 1;
  /* Other styles */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  font-family: 'Poppins', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.chipGreen {
  background-color: palegreen;
}

.chipRed {
  background-color: palevioletred;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
